<template>
    <div class="BoxToolTip">
        <span class="TitleToolTip">{{title}}</span>
        <span class="SubTitle mt-16">{{ contentTooltip }}</span>
        <span class="ActionBold mt-8">
                                {{ solution }}
                            </span>
        <li class="ActionText mt-8">
            {{ mainContent }}
        </li>
        <li class="ActionText mt-8" v-if="secondContentBool === true">
            {{ secondContent }}
        </li>
        <div class="Priority mt-16">
            <span class="PriorityTitle">{{ priority }}</span>
            <div class="WrapLevel mt-8" v-if="color === 'GREEN'">
                <div class="GraphFirst" :class="[color]"></div>
            </div>
            <div class="WrapLevel mt-8" v-if="color === 'BLUE'">
                <div class="GraphFirst" :class="[color]"></div>
                <div class="GraphEnd" :class="[color]"></div>
            </div>
            <div class="WrapLevel mt-8" v-if="color === 'YELLOW'">
                <div class="GraphFirst" :class="[color]"></div>
                <div class="GraphCenter" :class="[color]"></div>
                <div class="GraphEnd" :class="[color]"></div>
            </div>
            <div class="WrapLevel mt-8" v-if="color === 'RED'">
                <div class="GraphFirst" :class="[color]"></div>
                <div class="GraphCenter" :class="[color]"></div>
                <div class="GraphCenter" :class="[color]"></div>
                <div class="GraphEnd" :class="[color]"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BoxTooltip",
    props: {
        title: {
            type: String
        },
        contentTooltip: {
            type: String
        },
        solution: {
            type: String
        },
        mainContent: {
            type: String
        },
        priority: {
            type: String
        },
        color: {
            type: String
        },
        secondContentBool: {
            type: Boolean,
            default: false
        },
        secondContent: {
            type: String
        }
    }
}
</script>

<style lang="scss" scoped>
.BoxToolTip {
    visibility: hidden;
    width: 368px;
    height: fit-content;
    position: absolute;
    bottom: 50%;
    right: 0;
    border-radius: 16px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.2);
    padding: 24px;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    .TitleToolTip {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
    }
    .SubTitle {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
    }
    .ActionBold {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
    }
    .ActionText {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
    }
    .Priority {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        .WrapLevel {
            background: #EEEEEE;
            border-radius: 8px;
            width: 100%;
            height: 8px;
            display: grid;
            grid-template-columns: 80px 80px 80px 80px;
            column-gap: 1px;
            .GraphFirst {
                height: 100%;
                border-radius: 8px 0px 0px 8px;
            }
            .GraphCenter {
                height: 100%;
                width: 80px;
            }
            .GraphEnd {
                height: 100%;
                border-radius: 0px 8px 8px 0px;
            }
            .BLUE {
                background-color: #2D7D9A;
            }
            .GREEN {
                background-color: #10893E;
            }
            .YELLOW {
                background-color: #FFB900;
            }
            .RED {
                background-color: #FF4343;
            }
        }
    }
}

</style>