<template>
    <div class="wrapChart">
        <div class="textYaxis mt-40">

            {{$t("CustomerReport.DayVisit")}} ({{ typeOfDate }})
        </div>
        <div class="wrapYaxisValue mt-16">
            <div class="YaxisValue">
                <span class="yvalue" v-for="(type, index) in yValue" :key="index">
                    {{type}}
                </span>
            </div>
            <div class="ValueChart">
                <div class="FirtsValue">
                    <div class="Hibernating pd-16">
                        <div class="Title-Percent">
                            <span style="color: #FFFFFF;">{{typeCustomerSelected[typeOfCustomer[5]]}}</span>
                            <span class="mt-4px" style="color: #FFFFFF;">({{typeOfCustomerPercent[5]}}%)</span>
                        </div>
                        <div class="Quantity" style="color: #FFFFFF;">
                            {{ typeOfCustomerValue[5] }}
                        </div>
                        <BoxTooltip :title="typeCustomerSelected[typeOfCustomer[5]]"
                                    :content-tooltip="$t('CustomerReport.GraduallyDisappearTooltip')"
                                    :solution="$t('CustomerReport.ProposedSolution')"
                                    :main-content="$t('CustomerReport.GraduallyDisappearContent')"
                                    :priority="$t('CustomerReport.Priority')"
                                    :color="'GREEN'"
                        >
                        </BoxTooltip>
                    </div>
                    <div class="Cant-lose-them pd-16">
                        <div class="Title-Percent">
                            <span style="color: #FFFFFF;">{{typeCustomerSelected[typeOfCustomer[6]]}}</span>
                            <span class="mt-4px" style="color: #FFFFFF;">({{typeOfCustomerPercent[6]}}%)</span>
                        </div>
                        <div class="Quantity" style="color: #FFFFFF;">
                            {{ typeOfCustomerValue[6] }}
                        </div>
                        <BoxTooltip :title="typeCustomerSelected[typeOfCustomer[6]]"
                                    :content-tooltip= "$t('CustomerReport.CanNotLoseTooltip')"
                                    :solution="$t('CustomerReport.ProposedSolution')"
                                    :main-content="$t('CustomerReport.CanNotLoseContent')"
                                    :priority="$t('CustomerReport.Priority')"
                                    :color="'YELLOW'"
                        > </BoxTooltip>
                    </div>
                </div>
                <div class="WrapOtherValue">
                    <div class="RiskRecentValue">
                        <div class="RiskVlaue pd-16">
                            <div class="Title-Percent">
                                <span style="color: #FFFFFF;">{{typeCustomerSelected[typeOfCustomer[0]]}}</span>
                                <span class="mt-4px" style="color: #FFFFFF;">({{typeOfCustomerPercent[0]}}%)</span>
                            </div>
                            <div class="Quantity" style="color: #FFFFFF;">
                                {{ typeOfCustomerValue[0] }}
                            </div>
                            <BoxTooltip :title="typeCustomerSelected[typeOfCustomer[0]]"
                                        :content-tooltip= "$t('CustomerReport.RiskTooltip')"
                                        :solution="$t('CustomerReport.ProposedSolution')"
                                        :main-content="$t('CustomerReport.RiskContent')"
                                        :priority="$t('CustomerReport.Priority')"
                                        :color="'GREEN'"
                            > </BoxTooltip>
                        </div>
                        <div class="RecentValue pd-16">
                            <div class="Title-Percent">
                                <span style="color: #FFFFFF;">{{typeCustomerSelected[typeOfCustomer[2]]}}</span>
                                <span class="mt-2px" style="color: #FFFFFF;">({{typeOfCustomerPercent[2]}}%)</span>
                            </div>
                            <div class="Quantity" style="color: #FFFFFF;">
                                {{ typeOfCustomerValue[2] }}
                            </div>
                            <BoxTooltip :title="typeCustomerSelected[typeOfCustomer[2]]"
                                        :content-tooltip= "$t('CustomerReport.RecentTooltip')"
                                        :solution="$t('CustomerReport.ProposedSolution')"
                                        :main-content="$t('CustomerReport.RecentContent')"
                                        :priority="$t('CustomerReport.Priority')"
                                        :color="'BLUE'"
                                        :second-content-bool="true"
                                        :second-content="$t('CustomerReport.RecentContentSecond')"
                            > </BoxTooltip>
                        </div>
                    </div>
                    <div class="NeedPromisingValue">
                        <div class="NeedAtterntionValue pd-16">
                            <div class="Title-Percent">
                                <span style="color: #FFFFFF;">{{typeCustomerSelected[typeOfCustomer[4]]}}</span>
                                <span style="color: #FFFFFF;" class="mt-4px">({{typeOfCustomerPercent[4]}}%)</span>
                            </div>
                            <div class="Quantity" style="color: #FFFFFF;"> 
                                {{ typeOfCustomerValue[4] }}
                            </div>
                            <BoxTooltip :title="typeCustomerSelected[typeOfCustomer[4]]"
                                        :content-tooltip= "$t('CustomerReport.NeedCareTooltip')"
                                        :solution="$t('CustomerReport.ProposedSolution')"
                                        :main-content="$t('CustomerReport.NeedCareContent')"
                                        :priority="$t('CustomerReport.Priority')"
                                        :color="'YELLOW'"
                            > </BoxTooltip>
                            
                        </div>
                        <div class="PromisingValue pd-16">
                            <div class="Title-Percent">
                                <span style="color: #FFFFFF;">{{typeCustomerSelected[typeOfCustomer[3]]}}</span>
                                <span style="color: #FFFFFF;" class="mt-4px">({{typeOfCustomerPercent[3]}}%)</span>
                            </div>
                            <div class="Quantity" style="color: #FFFFFF;">
                                {{ typeOfCustomerValue[3] }}
                            </div>
                            <BoxTooltip :title="typeCustomerSelected[typeOfCustomer[3]]"
                                        :content-tooltip= "$t('CustomerReport.PromisingTooltip')"
                                        :solution="$t('CustomerReport.ProposedSolution')"
                                        :main-content="$t('CustomerReport.PromisingContent')"
                                        :priority="$t('CustomerReport.Priority')"
                                        :color="'RED'"
                            > </BoxTooltip>
                        </div>
                    </div>
                    <div class="LoyalValue pd-16">
                        <div class="Title-Percent">
                            <span style="color: #FFFFFF;">{{typeCustomerSelected[typeOfCustomer[1]]}}</span>
                            <span class="mt-4px" style="color: #FFFFFF;">({{typeOfCustomerPercent[1]}}%)</span>
                        </div>
                        <div class="Quantity" style="color: #FFFFFF;">
                            {{ typeOfCustomerValue[1] }}
                        </div>
                        <BoxTooltip :title="typeCustomerSelected[typeOfCustomer[1]]"
                                    :content-tooltip= "$t('CustomerReport.LoyalTooltip')"
                                    :solution="$t('CustomerReport.ProposedSolution')"
                                    :main-content="$t('CustomerReport.LoyalContent')"
                                    :priority="$t('CustomerReport.Priority')"
                                    :color="'BLUE'"
                        > </BoxTooltip>
                    </div>
                </div>
            </div>
        </div>
        <div class="wrapXaxisText">
            <div class="Empty">
            </div>
            <div class="wrapTextValueXaxis mt-16">
                <div class="xaxis">
                    <span class="xvalue" v-for="(range, index) in xValue" :key="index">
                        {{ range }}
                    </span>
                </div>
                <div class="textXaxis mt-16">
                    {{$t("CustomerReport.Frequency")}} ({{ typeOfDate }})
                </div>
            </div>
        </div>

    </div>
</template>
<script>


export default {
    components: {
        "BoxTooltip": () => import ('./boxTooltip.vue')  
    },
    name: "BehaviorChart",
    props: {
        xValue: {
            type: Array
        },
        yValue: {
            type: Array
        },
        typeOfCustomerValue: {
            type: Array
        },
        typeOfCustomer: {
            type: Array
        },
        typeOfCustomerPercent: {
            type: Array
        },
        typeOfDate: {
            type: String
        }
    },
    data() {
        return {
          typeCustomerSelected: {
            new: this.$t("CustomerReport.Recent"),
            potentialLoyalty: this.$t("CustomerReport.Promising"),
            loyal: this.$t("CustomerReport.Loyal"),
            needCare: this.$t("CustomerReport.NeedCare"),
            risk: this.$t("CustomerReport.Risk"),
            graduallyDisappear: this.$t("CustomerReport.GraduallyDisappear"),
            canNotLose: this.$t("CustomerReport.CanNotLose"),
          }
        }
    }
}
</script>
<style lang="scss" scoped>
.Title-Percent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}
                    
.wrapChart {
    .textYaxis {
        width: 100%;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #8C8C8C;
    }
    .wrapYaxisValue {
        display: grid;
        grid-template-columns: 52px 1fr;
        row-gap: 0;
        column-gap: 24px;
        .YaxisValue {
            height: 374px;
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-around;
            .yvalue {
                font-family: 'Roboto';
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #000;
            }
        }
        .ValueChart {
            height: 100%;
            background-color: #8c8c8c;
            .FirtsValue {
                display: flex;
                width: 100%;
                height: 134px;
                .Hibernating {
                    height: 100%;
                    width: 50%;
                    background-color: #FD2E2E;
                    position: relative;
                    .Title-Percent {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        font-family: 'Roboto';
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;
                        color: #FFFFFF;
                    }
                    .Quantity {
                        font-family: 'Roboto';
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 40px;
                        color: #FFFFFF;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    .BoxToolTip {
                        visibility: hidden;
                        width: 368px;
                        height: fit-content;
                        position: absolute;
                        bottom: 50%;
                        right: 0;
                        border-radius: 16px;
                        background-color: #FFFFFF;
                        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.2);
                        padding: 24px;
                        display: flex;
                        flex-direction: column;
                        .TitleToolTip {
                            font-family: 'Roboto';
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 24px;
                            color: #000000;
                        }
                        .SubTitle {
                            font-family: 'Roboto';
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #000000;
                        }
                        .ActionBold {
                            font-family: 'Roboto';
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            color: #000000;
                        }
                        .ActionText {
                            font-family: 'Roboto';
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #000000;
                        }
                        .Priority {
                            font-family: 'Roboto';
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            color: #000000;
                            .WrapLevel {
                                background: #EEEEEE;
                                border-radius: 8px;
                                width: 100%;
                                height: 8px;
                                display: grid;
                                grid-template-columns: 80px 80px 80px 80px; 
                                column-gap: 1px;
                                .GraphFirst {
                                    height: 100%;
                                    border-radius: 8px 0px 0px 8px;
                                    background-color: #FFB900;
                                }
                                .GraphCenter {
                                    height: 100%;
                                    width: 80px;
                                    background-color: #FFB900;
                                }
                                .GraphEnd {
                                    height: 100%;
                                    border-radius: 0px 8px 8px 0px;
                                    background-color: #FFB900;
                                }
                            }
                        }
                    }
                    &:hover .BoxToolTip{
                        visibility: visible;
                    }
                  
                }
                .Cant-lose-them {
                    height: 100%;
                    width: 50%;
                    background-color: #766161;
                    position: relative;
                    .Quantity {
                        font-family: 'Roboto';
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 40px;
                        color: #FFFFFF;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    &:hover .BoxToolTip{
                        visibility: visible;
                    }
                }
            }
        }
        .WrapOtherValue {
            display: flex;
            height: 240px;
            width: 100%;
            .RiskRecentValue {
                width: 25%;
                height: 100%;
                background-color:  #F9A52C;;
                .RiskVlaue {
                    height: 50%;
                    width: 100%;
                    background-color: #F9A52C;
                    position: relative;
                    .Quantity {
                        font-family: 'Roboto';
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 40px;
                        color: #FFFFFF;
                        position: absolute;
                        top: 50%;
                        left: 70%;
                        transform: translate(-50%, -50%);
                    }
                    &:hover .BoxToolTip{
                        visibility: visible;
                    }
                }
                .RecentValue {
                    height: 50%;
                    width: 100%;
                    background-color: #CDC7BE;;
                    position: relative;
                    .Quantity {
                        font-family: 'Roboto';
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 40px;
                        color: #FFFFFF;
                        position: absolute;
                        top: 50%;
                        left: 70%;
                        transform: translate(-50%, -50%);
                    }
                    &:hover .BoxToolTip{
                        visibility: visible;
                    }
                }
            }
            .NeedPromisingValue {
                width: 50%;
                height: 100%;
                background-color: #000000;
                .NeedAtterntionValue {
                    height: 50%;
                    width: 100%;
                    background-color: #87A7B3;;
                    position: relative;
                    .Quantity {
                        font-family: 'Roboto';
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 40px;
                        color: #FFFFFF;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    &:hover .BoxToolTip{
                        visibility: visible;
                    }
                }
                .PromisingValue {
                    height: 50%;
                    width: 100%;
                    background-color: #00818A;
                    position: relative;
                    .Quantity {
                        font-family: 'Roboto';
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 40px;
                        color: #FFFFFF;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    &:hover .BoxToolTip{
                        visibility: visible;
                    }
                }
            }
            .LoyalValue {
                width: 25%;
                height: 100%;
                background-color: #216583;
                position: relative;
                    .Quantity {
                        font-family: 'Roboto';
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 40px;
                        color: #FFFFFF;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                &:hover .BoxToolTip{
                    visibility: visible;
                }
            }
        }
    }
    .wrapXaxisText {
        row-gap: 0;
        column-gap: 24px;
        display: grid;
        grid-template-columns: 52px 1fr;
        .xaxis {
            height: 20px;
            width: 100%;
            display: flex;
            justify-content: space-around;
            .xvalue {
                font-family: 'Roboto';
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #000;
            }
        }
        .textXaxis {
            width: 100%;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #8C8C8C;
            display: flex;
            justify-content: flex-end;
        }
    }
}
.graph {
    background: #EEEEEE;
    border-radius: 8px;
}

</style>